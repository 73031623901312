import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import esES from 'vant/es/locale/lang/es-ES';
import msMy from 'vant/es/locale/lang/en-US';
import zhCn from 'vant/es/locale/lang/zh-CN';
import koKr from 'vant/es/locale/lang/en-US';
import jaJP from 'vant/es/locale/lang/ja-JP';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import common from './common/function';
import 'swiper/swiper-bundle.css';
import store from './store';
import VueI18n from 'vue-i18n';

import 'video.js/dist/video-js.css'

const lang = localStorage.getItem("lang") || "en_us";
if(lang == 'ko_kr'){
  Locale.use('ko_kr', koKr);
}
if(lang == 'ja_jp'){
  Locale.use('ja_jp', jaJP);
}
if(lang == 'en_us'){
  Locale.use('en_us', enUS);
}
if(lang == 'es_spa'){
  Locale.use('es_spa', esES);
}
if(lang == 'ms_my'){
  Locale.use('ms_my', msMy);
}
if(lang == 'zh_cn'){
  Locale.use('zh_cn', zhCn);
}

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
Vue.use(VueI18n);

const i18n = new VueI18n({
  globalInjection: true,
  locale: lang, // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'en_us': require('./assets/languages/en_us.json'),
    'es_spa': require('./assets/languages/es_spa.json'),
    'ms_my': require('./assets/languages/ms_my.json'),
    'zh_cn': require('./assets/languages/zh_cn.json'),
    'ko_kr': require('./assets/languages/ko_kr.json'),
    'ja_jp': require('./assets/languages/ja_jp.json'),
  }
});

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
